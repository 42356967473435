

















































































































// internal dependencies
import { FormMultisigAccountModificationTransactionTs } from './FormMultisigAccountModificationTransactionTs';

export default class FormMultisigAccountModificationTransaction extends FormMultisigAccountModificationTransactionTs {}
