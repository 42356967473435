






























import { AddCosignatoryInputTs } from './AddCosignatoryInputTs';

export default class AddCosignatoryInput extends AddCosignatoryInputTs {}
