


















import { RemoveCosignatoryInputTs } from './RemoveCosignatoryInputTs';

export default class RemoveCosignatoryInput extends RemoveCosignatoryInputTs {}
