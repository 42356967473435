var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin-bottom":"0.3rem"}},[(!(_vm.multisig && _vm.multisig.cosignatoryAddresses.length) && !_vm.addModifications.length && !_vm.removeModifications.length)?_c('FormRow',{scopedSlots:_vm._u([{key:"inputs",fn:function(){return [_c('div',[_c('div',{staticClass:"row-cosignatory-modification-display inputs-container empty-message mx-1"},[_c('span',[_vm._v(_vm._s(_vm.$t('message_empty_cosignatories')))])])])]},proxy:true}],null,false,4034179447)}):_vm._e(),_c('div',{staticClass:"form-row-inner-container"},[(_vm.cosignatories && _vm.cosignatories.length)?_c('FormRow',{scopedSlots:_vm._u([{key:"label",fn:function(){return undefined},proxy:true},{key:"inputs",fn:function(){return _vm._l((_vm.cosignatories),function(ref,index){
var address = ref.address;
return _c('div',{key:index,class:[
                        'row-cosignatory-modification-display',
                        'inputs-container',
                        'accent-pink-background',
                        'pl-2',
                        'pr-2',
                        'mx-1' ]},[_c('div',{staticClass:"cosignatory-address-container"},[(address)?_c('span',[_vm._v(_vm._s(address.plain()))]):_vm._e()]),(address && _vm.modifiable && _vm.removeModifications && _vm.removeModifications.length === 0)?_c('Icon',{staticClass:"icon-button",attrs:{"type":"md-trash","size":"21"},on:{"click":function($event){return _vm.onRemoveCosignatory(address)}}}):_c('span',[_vm._v(" ")])],1)})},proxy:true}],null,false,1518596272)}):_vm._e(),(_vm.modifiable && _vm.removeModifications && _vm.removeModifications.length)?_c('FormRow',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('Tooltip',{attrs:{"word-wrap":"","placement":"top-start","content":_vm.$t('form_label_removed_cosignatory_tooltip')}},[_c('Icon',{attrs:{"type":"ios-information-circle-outline"}})],1),_vm._v(" "+_vm._s(_vm.$t('form_label_removed_cosignatory'))+": ")]},proxy:true},{key:"inputs",fn:function(){return _vm._l((_vm.removeModifications),function(ref,index){
                    var address = ref.address;
return _c('div',{key:index,class:['row-cosignatory-modification-display', 'mx-1', 'pl-2', 'pr-2', 'red-background']},[_c('div',{staticClass:"cosignatory-address-container"},[_c('span',{staticClass:"cosignatory-removed"},[_vm._v(_vm._s(address.plain()))])]),_c('Icon',{staticClass:"icon-button",attrs:{"type":"ios-undo","size":"21"},on:{"click":function($event){return _vm.onUndoModification(address)}}})],1)})},proxy:true}],null,false,367457445)}):_vm._e(),(_vm.modifiable && _vm.addModifications && _vm.addModifications.length)?_c('FormRow',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('form_label_new_cosignatories'))+": ")]},proxy:true},{key:"inputs",fn:function(){return _vm._l((_vm.addModifications),function(ref,index){
                    var address = ref.address;
return _c('div',{key:index,class:[
                        'row-cosignatory-modification-display',
                        'inputs-container',
                        'mx-1',
                        'pl-2',
                        'pr-2',
                        'accent-pink-background' ]},[_c('div',{staticClass:"cosignatory-address-container"},[(address)?_c('span',[_vm._v(_vm._s(address.plain()))]):_vm._e()]),_c('img',{staticClass:"icon-button",staticStyle:{"font-size":"0.3rem"},attrs:{"src":require("@/views/resources/img/icons/bin.svg"),"size":"21"},on:{"click":function($event){return _vm.onUndoModification(address)}}})])})},proxy:true}],null,false,2212915591)}):_vm._e(),(_vm.isAddingCosignatory)?_c('AddCosignatoryInput',{on:{"added":_vm.onAddCosignatory}}):_vm._e(),(!_vm.isAddingCosignatory)?_c('div',{staticClass:"row-cosignatory-modification-display inputs-container link mx-1"},[_c('ButtonAdd',{staticClass:"clickable",attrs:{"size":24},on:{"click":function($event){_vm.isAddingCosignatory = true}}}),_c('a',{staticStyle:{"color":"#5200c6"},attrs:{"href":"#"},on:{"click":function($event){_vm.isAddingCosignatory = true}}},[_vm._v(_vm._s(_vm.$t('form_label_add_cosignatory')))])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }