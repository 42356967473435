import { render, staticRenderFns } from "./FormMultisigAccountModificationTransaction.vue?vue&type=template&id=1734c442&scoped=true&"
import script from "./FormMultisigAccountModificationTransaction.vue?vue&type=script&lang=ts&"
export * from "./FormMultisigAccountModificationTransaction.vue?vue&type=script&lang=ts&"
import style0 from "./FormMultisigAccountModificationTransaction.vue?vue&type=style&index=0&id=1734c442&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1734c442",
  null
  
)

export default component.exports