









































































































import { MultisigCosignatoriesDisplayTs } from './MultisigCosignatoriesDisplayTs';

export default class MultisigCosignatoriesDisplay extends MultisigCosignatoriesDisplayTs {}
