




















import { ApprovalAndRemovalInputTs } from './ApprovalAndRemovalInputTs';

export default class ApprovalAndRemovalInput extends ApprovalAndRemovalInputTs {}
